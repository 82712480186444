.bottomnavWrapper {
  background-color: #0078e0; /* used to be 2C3E50 */
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.bottomnav {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* Style the links inside the navigation bar */
.bottomnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 1rem;
}

/* Change the color of links on hover */
.bottomnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.bottomnav a.active {
  background-color: #0080ff;
  color: white;
}


.bottomnav button {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 1rem;
}

/* Change the color of links on hover */
.bottomnav button:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.bottomnav button.active {
  background-color: #0080ff;
  color: white;
}