.textWrapperHorizontalLanding {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  /*gap: 2rem;*/
}

.titleSection {
  gap:0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleLanding {
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}
